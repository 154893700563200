const mobile = '480px'
const tablet = '768px';
const desktop = '992px';
const wide_desktop = '1024px';
const ultra_wide_desktop = '1450px';

export default {
    mobile,
    tablet,
    desktop,
    wide_desktop,
    ultra_wide_desktop
}